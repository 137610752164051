import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { WordpressService } from '../core/services/wordpress.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // page$: Observable<any[]>;
  page: any;
  panel1: string;
  panel2: string;
  panel3: string;

  constructor(private wp: WordpressService) {}

  ngOnInit() {
    this.page = this.wp.getPagebySlug('home', {})
    .subscribe( data => {
      console.log( data );
      const pageData = data[0];
      this.panel1 = pageData.acf.panel_1;
      this.panel2 = pageData.acf.panel_2;
      this.panel3 = pageData.acf.panel_3;
    });
  }
}
