import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {map, take} from 'rxjs/operators';

import { WordpressService } from '../../core/services/wordpress.service';
import { post } from 'selenium-webdriver/http';


@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {
  page: any;
  pageData: any;
  slug$: Observable<string>;
  slugSubscriptions: Subscription;

  slug: string;
  constructor(private wp: WordpressService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.slug = this.route.snapshot.params['post'];
    this.pageData = this.wp.getCachedPostBySlug(this.slug);
    // console.log('blog-post 1', this.pageData);
    this.route.params.subscribe(
      (params: Params) => {
        this.slug = params['post'];
        this.pageData = this.wp.getCachedPostBySlug(this.slug);
        // console.log('blog-post 2', this.pageData);
      }
    );


    // this.slug$ = this.route.paramMap
    //     .pipe(
    //       map(params => (params.get('post')))
    //     );

    //  this.slugSubscriptions = this.slug$.pipe(
    //     take(1))
    //     .subscribe(slug => {
    //     console.log(slug);
    //       this.page = this.wp.getPostbySlug(slug, {})
    //       .subscribe( data => {
    //         console.log( data );
    //         this.pageData = data[0];
    //       });
    //         // .then((res) => {
    //         //   this.pageData = res[0];
    //         // }).catch((res) => {
    //         //   console.log('error', res);
    //         // });
    //     });
  }

}
