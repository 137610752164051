import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  configUrl = 'http://cms.herbalurbanist.com/wp-json/wp/v2/';
  blogposts: any;
  products: any;
  postDataSubject = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  getPosts(params: any): any {
    return  this.httpClient.get<any[]>( this.configUrl + 'posts?categories=3&_embed', {
      params: params
    });
  }
  getCachedPosts() {
    return this.blogposts;
  }
  setPostsCache(posts: any) {
    this.blogposts = posts;
    this.postDataSubject.next(posts);
  }
  getCachedPostBySlug(slug: string) {
    // console.log('getCachedPostBySlug', this.blogposts );
    for (let i = 0; i < this.blogposts.length; i++) {
      if (this.blogposts[i].slug === slug) {
        return this.blogposts[i];
      }
    }
  }
  getPostbySlug(slug: string, params: any): any {
    return this.httpClient.get<any[]>( this.configUrl + 'posts?_embed&slug=' + slug, {
      params: params
    });
  }
  getPagebySlug(slug: string, params: any) {
    return this.httpClient.get<any[]>( this.configUrl + 'pages?_embed&slug=' + slug, {
      params: params
    });
  }
  getProducts(params: any) {
    return this.httpClient.get<any[]>(this.configUrl + 'posts?categories=2&_embed', {
      params: params
    });
  }
  setProductCache(products: any) {
    this.products = products;
  }
}
