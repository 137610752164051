import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  /* name: string;
   email: string;
   message: string;*/
  showForm: boolean = true;
  // change to reactive form
  contactForm: FormGroup;

  constructor(private snackbar: MatSnackBar,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'message': new FormControl(null, [Validators.required])
    });
  }

  onSubmitReactive() {
    if (!this.contactForm.valid) {
      this.openSnackBar('Please fix your form. See errors in red.')
    } else {
      this.sendMessage();
      this.showForm = false;
      setTimeout(() => {
        this.contactForm.reset();
        this.showForm = true;
      }, 10);
    }
  }

  sendMessage() {
    // this is valid nodejs approach
    // let data = {
    //   contactEmail: this.contactForm.get('email').value,
    //   contactName: this.contactForm.get('name').value,
    //   contactMessage: this.contactForm.get('message').value
    // };
    // //console.log('sendMessage data',data);
    // this.http.post<any>('http://localhost:3000/sendMail', data)
    //   .subscribe( (res) => {
    //     console.log('send message response', res);
    // });

    // using php
    const phpdata = {
      functionname: 'sendmail',
      arguments: [
        this.contactForm.get('name').value,
        this.contactForm.get('email').value,
        this.contactForm.get('message').value
      ]
    };
    console.log('phpdata', phpdata);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http.post<any>('secure_email.php', phpdata, httpOptions)
      .subscribe((res) => {
        console.log('sendMessage response: ', res);
      }, error => {
        console.log('sendMessage error: ', error);
      });
  }

  openSnackBar(message: string) {
    this.snackbar.open(message, 'OK', {
      duration: 4000,
    });
  }
}
