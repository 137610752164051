import { Component, OnInit, Input } from '@angular/core';
import { WordpressService } from '../../core/services/wordpress.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.scss']
})
export class BlogSidebarComponent implements OnInit {
  //@Input() posts: any;
  posts: any;
  constructor(private wp: WordpressService,
    private router: Router) {}

  ngOnInit() {
    this.posts = this.wp.getCachedPosts();
    // console.log('in blog-posts-list component 1: ', this.posts);
    if (this.posts === undefined || this.posts === null ) {
      this.wp.postDataSubject.subscribe( (posts: any) => {
        this.posts =  posts;
        // console.log('in blog-posts-list component 2: ', this.posts);
      });
    }
  }
  navToPost(slug: string) {
    console.log('slug', slug);
    console.log(this.posts);
    this.router.navigate(['blog/' + slug]);
  }

}
