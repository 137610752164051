import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { AuthGuard } from './auth/auth.guard';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { ProductsComponent } from './products/products.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { BlogPostListComponent } from './blog/blog-post-list/blog-post-list.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'blog', component: BlogComponent ,
    children: [
      { path: 'posts', component: BlogPostListComponent},
      { path: ':post', component: BlogPostComponent }
    ]},
  { path: 'products', component: ProductsComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', component: PageNotFoundComponent }
  // { path: 'user', loadChildren: './auth/auth.module#AuthModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: []  // AuthGuard
})
export class AppRoutingModule {}
