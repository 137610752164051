import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WordpressService } from 'src/app/core/services/wordpress.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog-post-list',
  templateUrl: './blog-post-list.component.html',
  styleUrls: ['./blog-post-list.component.scss']
})
export class BlogPostListComponent implements OnInit, OnDestroy {
  // @Input() posts: any;
  posts: any;
  postsUpdate: Subscription;

  constructor( private router: Router,
    private wp: WordpressService) {}

  ngOnInit() {
    this.posts = this.wp.getCachedPosts();
    // console.log('in blog-posts-list component 1: ', this.posts);
    if (this.posts === undefined || this.posts === null ) {
      this.postsUpdate = this.wp.postDataSubject.subscribe( (posts: any) => {
        this.posts =  posts;
        // console.log('in blog-posts-list component 2: ', this.posts);
      });
    }
  }
  navToPost(slug: string) {
    this.router.navigate(['blog/' + slug]);
  }
  ngOnDestroy() {
    // console.log('this.postsUpdate', this.postsUpdate);
    if (this.postsUpdate !== undefined) {this.postsUpdate.unsubscribe(); }
  }
}
