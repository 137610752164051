import { Component, OnInit } from '@angular/core';
import { WordpressService } from '../core/services/wordpress.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  page: any;
  posts: any;

  constructor(private wp: WordpressService,
              private router: Router) {}

  ngOnInit() {
    this.page = this.wp.getPosts({}) // per_page: '10'
    .subscribe( data => {
      // console.log( data );
      this.posts = data;
      this.wp.setPostsCache(this.posts);
    });

  }

}
