import { Component, OnInit } from '@angular/core';
import { WordpressService } from '../core/services/wordpress.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  page: any;
  products: any;
  constructor(private wp: WordpressService) { }

  ngOnInit() {
    this.page = this.wp.getProducts({}) // per_page: '10'
    .subscribe( data => {
      this.products = data;
      console.log( 'products', this.products );
      this.wp.setProductCache(this.products);
    });
  }

}
