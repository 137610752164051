import { Component, OnInit } from '@angular/core';

import { WordpressService } from '../core/services/wordpress.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  page: any;
  aboutpage: string;
  constructor (private wp: WordpressService) { }

  ngOnInit() {
    this.page = this.wp.getPagebySlug('about-me', {})
    .subscribe( data => {
      console.log( data );
      const pageData = data[0];
      this.aboutpage = pageData.content.rendered;
    });
  }

}
